import { defineComponent, ref } from 'vue';
import { Selector } from '@/components/UI';
import useLocation from '@/compositions/useLocation';
import { locations } from '@/types/location';
import { useToast } from 'vue-toastification';
export default defineComponent({
    name: 'LocationSelector',
    components: {
        Selector
    },
    setup() {
        const location = useLocation();
        const value = ref(location.currentLocationId.value);
        const options = Object.entries(locations).map(([key, location]) => {
            return {
                value: key,
                text: location.name
            };
        });
        return {
            value,
            options,
            ...location,
            toast: useToast()
        };
    },
    methods: {
        updateLocation() {
            this.updateLocationId(this.value);
            this.toast.success('Location Updated');
        }
    }
});
