import { defineComponent } from 'vue';
import LoginScreen from './LoginScreen';
import { Debugger } from '@/components/Widgets';
import Notifications from '@/components/General/Notifications';
import { version } from '@/env';
import '@/sass/transitions.scss';
import './Dashboard.scss';
import { HealthStatus } from '@/types/hardware';
import useMobile from '@/compositions/useMobile';
import useLocation from '@/compositions/useLocation';
import { LocationSelector } from '@/components/Location';
const variants = {
    [HealthStatus.HEALTHY]: {
        variant: 'success',
        label: 'OK'
    },
    [HealthStatus.UNHEALTHY]: {
        variant: 'danger',
        label: '!'
    },
    [HealthStatus.WARNING]: {
        variant: 'warning',
        label: '!'
    },
    [HealthStatus.UNKNOWN]: {
        variant: 'secondary',
        label: '?'
    }
};
export default defineComponent({
    name: 'Dashboard',
    components: {
        LoginScreen,
        Notifications,
        Debugger,
        LocationSelector
    },
    setup() {
        return {
            ...useMobile(),
            ...useLocation()
        };
    },
    data: () => ({
        tab: 'teams',
        healthChecking: false
    }),
    computed: {
        isloggedin() {
            return this.$store.direct.state.auth.loggedin;
        },
        username() {
            return this.$store.direct.state.auth.username;
        },
        rights() {
            if (!this.$store.direct.state.auth.rights) {
                return '';
            }
            return this.$store.direct.state.auth.rights;
        },
        user() {
            return this.$store.direct.state.auth.user;
        },
        health() {
            return this.$store.direct.state.hardware.status.status;
        },
        healthVariant() {
            return variants[this.health];
        },
        softwareVersion() {
            return version.frontend;
        },
        imageurl() {
            if (this.user) {
                return `https://${this.currentLocation.apiurl}/media/${this.user.displayimage}`;
            }
            return '';
        },
        background() {
            return {
                backgroundImage: 'url(' + require('@/assets/bg-dashboard.png') + ')'
            };
        },
        serviceWorkers() {
            return null; // this.$swRegistration ? this.$swRegistration : null
        }
    },
    watch: {
        isloggedin: {
            handler(newValue, oldValue) {
                var _a, _b, _c;
                if (newValue && !oldValue) {
                    this.$store.direct.dispatch.auth.get().catch(this.logError);
                    if (!this.healthChecking) {
                        this.$store.direct.dispatch.hardware.subscribe().catch(this.logError);
                        this.healthChecking = true;
                    }
                    if (this.$route.path !== '/') {
                        this.tab = this.$route.path.replace('/', '');
                    }
                    if (((_c = (_b = (_a = this.$router) === null || _a === void 0 ? void 0 : _a.currentRoute) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.fullPath) === '/') {
                        this.$nextTick(() => { this.goTo('teams'); });
                    }
                }
                if (!newValue && oldValue && this.healthChecking) {
                    this.$store.direct.dispatch.hardware.unsubscribe().catch(this.logError);
                    this.healthChecking = false;
                }
            }, immediate: true
        },
        // @ts-ignore
        '$route'(to, _from) {
            document.title = to.meta.title || 'Beat Out';
        }
    },
    async mounted() {
        var _a, _b, _c;
        await this.$store.direct.dispatch.auth.loadFromCookies();
        if (((_c = (_b = (_a = this.$router) === null || _a === void 0 ? void 0 : _a.currentRoute) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.fullPath) === '/' && this.isloggedin) {
            this.$nextTick(() => { this.goTo('teams'); });
        }
    },
    destroyed() {
        if (this.healthChecking) {
            this.$store.direct.dispatch.hardware.unsubscribe().catch(this.logError);
        }
    },
    methods: {
        async logout() {
            try {
                await this.$store.direct.dispatch.auth.logout();
                window.location.href = '/';
            }
            catch (error) {
                if (error instanceof Error) {
                    this.logError(error);
                }
            }
        },
        openInTab(url) {
            const win = window.open(url, '_blank');
            if (win) {
                win.focus();
            }
        },
        logError(error) {
            console.log(`Error detect: ${error}`);
        },
        goTo(tab) {
            this.$router.push({ path: `/${tab}` });
            this.tab = tab;
            const menuToggle = document.querySelector('#navbarSupportedContent');
            menuToggle === null || menuToggle === void 0 ? void 0 : menuToggle.classList.remove('show');
        }
    }
});
