import { defineComponent } from 'vue';
import LocationSelector from '@/components/Location/LocationSelector';
export default defineComponent({
    name: 'LoginScreen',
    components: {
        LocationSelector
    },
    data: () => ({
        username: '',
        password: '',
        errormessage: ''
    }),
    methods: {
        async login() {
            try {
                await this.$store.direct.dispatch.auth.login({ username: this.username, password: this.password });
                this.errormessage = '';
            }
            catch (error) {
                this.errormessage = 'Login or password not correct';
                console.log('Error logging in', error);
            }
        }
    }
});
