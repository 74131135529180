import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Notifications',
    props: {
        swRegistration: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        appServerPublicKey: 'BPF9LrihCsLvMAINX_ROHabtisr0UJlDjTIrh5UmGqynkfrb7dVyUjTHOuzJjzRzjf2915ntIocDs8U0h2V_Bw8',
        isSubscribed: false,
        subscription: null
    }),
    mounted() {
        if (this.swRegistration !== null) {
            this.swRegistration.pushManager.getSubscription()
                .then(subscription => {
                this.isSubscribed = subscription !== null;
                this.updateSubscriptionOnServer(subscription);
                if (this.isSubscribed) {
                    console.log('User IS subscribed.');
                }
                else {
                    console.log('User is NOT subscribed.');
                    this.subscribeUser();
                }
            })
                .catch(error => {
                console.error('Push notification errors', error);
            });
        }
    },
    methods: {
        urlB64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/\-/g, '+') // eslint-disable-line
                .replaceAll('_', '/'); // eslint-disable-line
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },
        subscribeUser() {
            const applicationServerKey = this.urlB64ToUint8Array(this.appServerPublicKey);
            this.swRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey
            })
                .then(subscription => {
                console.log('User is subscribed.');
                this.updateSubscriptionOnServer(subscription);
                this.isSubscribed = true;
            })
                .catch(error => {
                console.log('Failed to subscribe the user:', error);
            });
        },
        unsubscribeUser() {
            this.swRegistration.pushManager.getSubscription()
                .then(subscription => {
                if (subscription) {
                    return subscription.unsubscribe();
                }
                return null;
            })
                .catch(error => {
                console.log('Error unsubscribing', error);
            })
                .then(() => {
                this.updateSubscriptionOnServer(null);
                console.log('User is unsubscribed.');
                this.isSubscribed = false;
                // updateBtn()
            })
                .catch(error => {
                console.log('Error unsubscribing', error);
            });
        },
        updateSubscriptionOnServer(subscription) {
            // TODO: Send subscription to application server
            if (subscription !== null) {
                this.subscription = subscription;
                this.$store.direct.dispatch.auth.setNotificationSubscription(subscription);
            }
        }
    },
    render: () => null
});
